import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Seccion.css';

// Importa la imagen para la sección 2
import seccion2Image from '../img/seccion2.png';

const conceptos = [
  { id: 1, titulo: "Ley General de Protección de Datos Personales", descripcion: "Regula el tratamiento de datos personales en México. Esta ley establece principios y obligaciones que deben seguirse al recolectar y procesar datos personales." },
  { id: 2, titulo: "Ley de Propiedad Industrial", descripcion: "Protege los derechos de propiedad industrial en México, incluyendo patentes, marcas y secretos industriales." },
  { id: 3, titulo: "Ley Federal de Derechos de Autor", descripcion: "Establece los derechos de los creadores de obras literarias y artísticas, otorgándoles protección sobre sus creaciones." },
  { id: 4, titulo: "Ley Federal de Datos Personales", descripcion: "Regula cómo las entidades privadas manejan los datos personales, garantizando la privacidad y la protección de los individuos." },
  { id: 5, titulo: "Código Penal Federal", descripcion: "Establece sanciones para delitos relacionados con la privacidad y la protección de la información personal." },
  { id: 6, titulo: "Ley General de Transparencia", descripcion: "Proporciona acceso a la información pública y fomenta la transparencia en la administración del gobierno y las instituciones públicas." },
  { id: 7, titulo: "Criptografía Simétrica", descripcion: "Método de cifrado donde la misma clave se utiliza para cifrar y descifrar datos. Es eficiente pero presenta riesgos si la clave es comprometida." },
  { id: 8, titulo: "Criptografía Asimétrica", descripcion: "Utiliza un par de claves, una pública y otra privada, para cifrar y descifrar datos. Es ampliamente utilizada en sistemas modernos de seguridad." },
  { id: 9, titulo: "Cifrado por Bloques", descripcion: "Cifra los datos en bloques de tamaño fijo, proporcionando un alto nivel de seguridad en aplicaciones como transacciones bancarias." },
  { id: 10, titulo: "Criptoanálisis", descripcion: "El estudio de técnicas para descifrar datos cifrados sin conocer la clave. Es la base de muchos ataques criptográficos." }
];

const Seccion2 = () => {
  const [selectedConcept, setSelectedConcept] = useState(null);

  const handleClick = (id) => {
    setSelectedConcept(id === selectedConcept ? null : id);
  };

  return (
    <div className="section-container">
      <h1 className="section-title">Confidencialidad de la Información</h1>

      <div className="concept-grid">
        {conceptos.map((concepto) => (
          <div key={concepto.id} className="concept-card" onClick={() => handleClick(concepto.id)}>
            <h3>{concepto.titulo}</h3>
            {selectedConcept === concepto.id && <p className="concept-description">{concepto.descripcion}</p>}
          </div>
        ))}
      </div>

      {/* Imagen debajo de los conceptos */}
      <img src={seccion2Image} alt="Imagen Sección 2" className="section-image" />

      <Link to="/" className="main-button">Inicio</Link>
    </div>
  );
};

export default Seccion2;
