import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Seccion.css';

// Importa la imagen para la sección 1
import seccion1Image from '../img/seccion1.png';

const conceptos = [
  { id: 1, titulo: "DDoS", descripcion: "Es un ataque que sobrecarga un servidor o una red con tráfico excesivo, de modo que los usuarios legítimos no pueden acceder al servicio. Los ataques DDoS suelen ser ejecutados por una red de computadoras infectadas, conocida como botnet." },
  { id: 2, titulo: "Ordenador Zombie", descripcion: "Un ordenador infectado con malware y controlado remotamente por un atacante. El ordenador zombie suele formar parte de una botnet para ejecutar ataques masivos sin que el usuario sea consciente." },
  { id: 3, titulo: "Ciberdelincuente", descripcion: "Individuos que utilizan tecnología para cometer delitos como el robo de datos o fraude. Pueden trabajar de forma independiente o como parte de grupos criminales organizados." },
  { id: 4, titulo: "Botmaster", descripcion: "Persona que controla una red de bots (ordenadores infectados) para realizar ataques. El botmaster puede utilizar estos bots para llevar a cabo ataques DDoS, enviar spam o robar información." },
  { id: 5, titulo: "Botnet", descripcion: "Red de ordenadores infectados, utilizados para realizar ataques en masa. Las botnets pueden ser alquiladas o vendidas en el mercado negro por ciberdelincuentes." },
  { id: 6, titulo: "Ransomware", descripcion: "Malware que encripta los archivos y exige un rescate para restablecer el acceso. Las víctimas de ransomware a menudo se ven obligadas a pagar grandes sumas de dinero para recuperar sus datos." },
  { id: 7, titulo: "Disclaimer", descripcion: "Declaración que niega responsabilidad, especialmente útil para delimitar la responsabilidad en el uso de tecnologías que pueden ser utilizadas de forma maliciosa." },
  { id: 8, titulo: "The Morris Worm", descripcion: "Uno de los primeros gusanos de Internet que infectó miles de sistemas en 1988. Su creador, Robert Morris, fue uno de los primeros condenados bajo la Ley de Fraude y Abuso Informático." },
  { id: 9, titulo: "Ciberwarfare", descripcion: "El uso de ciberataques por parte de naciones con fines estratégicos o militares. Incluye ataques a infraestructuras críticas, robo de secretos de estado o sabotaje a redes." },
  { id: 10, titulo: "Stuxnet", descripcion: "Un sofisticado malware que saboteó instalaciones industriales como plantas nucleares, diseñado para interferir con sistemas de control industrial." },
  { id: 11, titulo: "Moonlight Maze", descripcion: "Uno de los primeros grandes ciberataques dirigidos a agencias de EE.UU. en los 90, que comprometió redes militares y gubernamentales." },
  { id: 12, titulo: "Operación Aurora", descripcion: "Ataque cibernético dirigido a Google y otras compañías en 2009. El ataque se originó en China y buscaba obtener propiedad intelectual." },
  { id: 13, titulo: "Freeze it into Submission", descripcion: "Táctica que sobrecarga un sistema hasta que deja de funcionar, similar a un DDoS. A menudo es utilizado como una técnica para ganar tiempo durante un ataque." },
  { id: 14, titulo: "Wannacry", descripcion: "Ransomware de 2017 que afectó a miles de computadoras en todo el mundo, encriptando datos y exigiendo pagos en Bitcoin." },
  { id: 15, titulo: "Petya", descripcion: "Ransomware que infecta el registro de arranque de los sistemas, bloqueando el acceso al sistema completo hasta que se pague un rescate." },
  { id: 16, titulo: "Equifax (Ataque)", descripcion: "En 2017, un ataque comprometió los datos de más de 147 millones de personas, incluyendo números de seguridad social y otra información sensible." },
  { id: 17, titulo: "Cam4 (Ataque)", descripcion: "Filtración masiva en la plataforma Cam4 que expuso más de 10 mil millones de registros, incluyendo direcciones IP, nombres de usuarios, y correos electrónicos." }
];

const Seccion1 = () => {
  const [selectedConcept, setSelectedConcept] = useState(null);

  const handleClick = (id) => {
    setSelectedConcept(id === selectedConcept ? null : id);
  };

  return (
    <div className="section-container">
      <h1 className="section-title">Ataques Cibernéticos</h1>

      <div className="concept-grid">
        {conceptos.map((concepto) => (
          <div key={concepto.id} className="concept-card" onClick={() => handleClick(concepto.id)}>
            <h3>{concepto.titulo}</h3>
            {selectedConcept === concepto.id && <p className="concept-description">{concepto.descripcion}</p>}
          </div>
        ))}
      </div>

      {/* Imagen debajo de los conceptos */}
      <img src={seccion1Image} alt="Imagen Sección 1" className="section-image" />

      <Link to="/" className="main-button">Inicio</Link>
    </div>
  );
};

export default Seccion1;
