import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Seccion.css';

// Importa la imagen para la sección 3
import seccion3Image from '../img/seccion3.jpg';

const conceptos = [
  { id: 1, titulo: "Accesibilidad", descripcion: "Garantiza que la información esté disponible para quienes la necesitan, cuando la necesiten. Sin accesibilidad, los sistemas de seguridad pueden fallar, incluso si la información es segura." },
  { id: 2, titulo: "Confidencialidad", descripcion: "Protege la información para que solo los usuarios autorizados puedan acceder a ella. La confidencialidad es un pilar en la seguridad de la información y se mantiene mediante el cifrado y controles de acceso." },
  { id: 3, titulo: "Disponibilidad", descripcion: "Asegura que la información esté disponible para su uso cuando sea necesario. Los sistemas deben ser resistentes a fallos para garantizar que la información siempre esté disponible." },
  { id: 4, titulo: "Autenticación", descripcion: "Verifica la identidad de un usuario antes de darle acceso a la información. Los sistemas de autenticación incluyen contraseñas, tokens de seguridad y autenticación multifactor." },
  { id: 5, titulo: "Integridad", descripcion: "Asegura que la información no ha sido alterada o manipulada sin autorización. La integridad se mantiene mediante controles de validación y técnicas criptográficas." },
  { id: 6, titulo: "Control de Acceso", descripcion: "Establece quién puede acceder a ciertos datos y recursos. El control de acceso asegura que solo los usuarios autorizados puedan realizar acciones específicas en un sistema." }
];

const Seccion3 = () => {
  const [selectedConcept, setSelectedConcept] = useState(null);

  const handleClick = (id) => {
    setSelectedConcept(id === selectedConcept ? null : id);
  };

  return (
    <div className="section-container">
      <h1 className="section-title">Principios de Seguridad de la Información</h1>

      <div className="concept-grid">
        {conceptos.map((concepto) => (
          <div key={concepto.id} className="concept-card" onClick={() => handleClick(concepto.id)}>
            <h3>{concepto.titulo}</h3>
            {selectedConcept === concepto.id && <p className="concept-description">{concepto.descripcion}</p>}
          </div>
        ))}
      </div>

      {/* Imagen debajo de los conceptos */}
      <img src={seccion3Image} alt="Imagen Sección 3" className="section-image" />

      <Link to="/" className="main-button">Inicio</Link>
    </div>
  );
};

export default Seccion3;
