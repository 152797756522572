import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Seccion1 from './pages/Seccion1';
import Seccion2 from './pages/Seccion2';
import Seccion3 from './pages/Seccion3';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/seccion1" element={<Seccion1 />} />
          <Route path="/seccion2" element={<Seccion2 />} />
          <Route path="/seccion3" element={<Seccion3 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
