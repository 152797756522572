import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

// Importa las imágenes de la carpeta 'src/img'
import kevinImage from '../img/kevin.jpg';
import seginfoImage from '../img/seginfo.jpg';

const Home = () => {
  return (
    <div className="home-container">
      <header className="header">
        <div className="student-info-container">
          {/* Usa la imagen importada */}
          <img 
            src={kevinImage} 
            alt="Foto del alumno" 
            className="student-image"
          />
          <p className="student-info">MURILLO TORRES KEVIN RAFAEL</p>
        </div>
        <nav className="nav">
          <Link to="/seccion1">Sección 1</Link>
          <Link to="/seccion2">Seccion 2</Link>
          <Link to="/seccion3">Sección 3</Link>
          <Link to="/">Inicio</Link>
        </nav>
      </header>

      <main className="main-content">
        <section className="info-section">
          {/* Usa la imagen importada */}
          <img src={seginfoImage} alt="Seguridad de la información" className="main-image" />
          <div className="info-text">
            <h2>LA SEGURIDAD DE LA INFORMACIÓN</h2>
            <p>
              La seguridad de la información es un campo de máxima prioridad en un mundo cada vez más digitalizado.
              Su objetivo principal es proteger la confidencialidad, integridad y disponibilidad de la información
              ante una amplia variedad de amenazas, como el acceso no autorizado, la divulgación, la alteración o 
              la destrucción de datos. Esto se logra mediante la implementación de políticas, procesos y tecnologías
              diseñadas para proteger los datos, tanto en formato físico como digital.
            </p>
            <button className="main-button">Inicio</button>
          </div>
        </section>

        <div className="gallery">
          <div className="gallery-item">
            <Link to="/seccion1">1. Ataques Cibernéticos</Link>
          </div>
          <div className="gallery-item">
            <Link to="/seccion2">2. Confidencialidad de la Información</Link>
          </div>
          <div className="gallery-item">
            <Link to="/seccion3">3. Principios de Seguridad de la Información</Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
